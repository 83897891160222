const data: any[] = 
[{
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553699994944",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6770818",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:19:54.944Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "49",
  "产水泵运行时间s (S)": "20",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "1",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "43",
  "系统运行时间s (S)": "32",
  "累积用电量KWh (S)": "54317.64453125",
  "膜产水压力值 (S)": "-22.6680202484131",
  "膜产水瞬时值 (S)": "38.2000007629395",
  "膜产水累积量m3 (S)": "56887",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "0",
  "设备有功功率W (S)": "3743",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700021365",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6772606",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:20:21.365Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "55",
  "系统运行时间s (S)": "21",
  "累积用电量KWh (S)": "54320.17578125",
  "膜产水压力值 (S)": "7.46408081054687",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2691",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700030816",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6772606",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:20:30.816Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "55",
  "系统运行时间s (S)": "26",
  "累积用电量KWh (S)": "54320.1953125",
  "膜产水压力值 (S)": "7.61217498779297",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2701",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700040817",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6773978",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:20:40.817Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "55",
  "系统运行时间s (S)": "36",
  "累积用电量KWh (S)": "54320.22265625",
  "膜产水压力值 (S)": "7.75530195236206",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2699",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700050826",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6773978",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:20:50.826Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "55",
  "系统运行时间s (S)": "46",
  "累积用电量KWh (S)": "54320.2578125",
  "膜产水压力值 (S)": "7.84469985961914",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2689",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700060855",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6773978",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:21:00.855Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "55",
  "系统运行时间s (S)": "56",
  "累积用电量KWh (S)": "54320.28515625",
  "膜产水压力值 (S)": "7.90429878234863",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2695",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700070831",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6775214",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:21:10.831Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "56",
  "系统运行时间s (S)": "6",
  "累积用电量KWh (S)": "54320.31640625",
  "膜产水压力值 (S)": "7.95712471008301",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2690",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700080813",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6775214",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:21:20.813Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "56",
  "系统运行时间s (S)": "16",
  "累积用电量KWh (S)": "54320.34375",
  "膜产水压力值 (S)": "8.05103778839111",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2697",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700090822",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6775214",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:21:30.822Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "56",
  "系统运行时间s (S)": "21",
  "累积用电量KWh (S)": "54320.35546875",
  "膜产水压力值 (S)": "8.05284404754639",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2696",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700100829",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6776450",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:21:40.829Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "28",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "56",
  "系统运行时间s (S)": "40",
  "累积用电量KWh (S)": "54320.4296875",
  "膜产水压力值 (S)": "-21.265645980835",
  "膜产水瞬时值 (S)": "12.6000003814697",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3273",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700110838",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6776450",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:21:50.838Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "38",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "56",
  "系统运行时间s (S)": "50",
  "累积用电量KWh (S)": "54320.4609375",
  "膜产水压力值 (S)": "-23.0197429656982",
  "膜产水瞬时值 (S)": "35.4000015258789",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3274",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700120854",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6776450",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:22:00.854Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "44",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "56",
  "系统运行时间s (S)": "56",
  "累积用电量KWh (S)": "54320.4765625",
  "膜产水压力值 (S)": "-23.0669250488281",
  "膜产水瞬时值 (S)": "45.5",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3209",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700130817",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6777686",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:22:10.817Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "58",
  "产水泵运行时间s (S)": "53",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "57",
  "系统运行时间s (S)": "5",
  "累积用电量KWh (S)": "54320.51171875",
  "膜产水压力值 (S)": "-20.5838718414307",
  "膜产水瞬时值 (S)": "49.0999984741211",
  "膜产水累积量m3 (S)": "56893",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3182",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700140828",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6777686",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:22:20.828Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "59",
  "产水泵运行时间s (S)": "8",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "57",
  "系统运行时间s (S)": "20",
  "累积用电量KWh (S)": "54320.5703125",
  "膜产水压力值 (S)": "-22.9021263122559",
  "膜产水瞬时值 (S)": "37",
  "膜产水累积量m3 (S)": "56894",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3234",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700150819",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6777686",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:22:30.819Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "59",
  "产水泵运行时间s (S)": "8",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "57",
  "系统运行时间s (S)": "20",
  "累积用电量KWh (S)": "54320.5703125",
  "膜产水压力值 (S)": "-22.9021263122559",
  "膜产水瞬时值 (S)": "37",
  "膜产水累积量m3 (S)": "56894",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3234",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700160862",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6778716",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:22:40.862Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "59",
  "产水泵运行时间s (S)": "23",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "57",
  "系统运行时间s (S)": "35",
  "累积用电量KWh (S)": "54320.62109375",
  "膜产水压力值 (S)": "-20.2357616424561",
  "膜产水瞬时值 (S)": "34.9000015258789",
  "膜产水累积量m3 (S)": "56894",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3197",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700170822",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6778716",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:22:50.822Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "59",
  "产水泵运行时间s (S)": "33",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "57",
  "系统运行时间s (S)": "45",
  "累积用电量KWh (S)": "54320.65625",
  "膜产水压力值 (S)": "-23.0095844268799",
  "膜产水瞬时值 (S)": "34",
  "膜产水累积量m3 (S)": "56894",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3236",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700180809",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6778716",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:23:00.809Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "59",
  "产水泵运行时间s (S)": "38",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "57",
  "系统运行时间s (S)": "50",
  "累积用电量KWh (S)": "54320.67578125",
  "膜产水压力值 (S)": "-21.2931861877441",
  "膜产水瞬时值 (S)": "34.0999984741211",
  "膜产水累积量m3 (S)": "56894",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3157",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700190806",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6778716",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:23:10.806Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "5",
  "产水泵运行时间m (S)": "59",
  "产水泵运行时间s (S)": "38",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "57",
  "系统运行时间s (S)": "50",
  "累积用电量KWh (S)": "54320.67578125",
  "膜产水压力值 (S)": "-21.2931861877441",
  "膜产水瞬时值 (S)": "34.0999984741211",
  "膜产水累积量m3 (S)": "56894",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3157",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700200829",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6779952",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:23:20.829Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "0",
  "产水泵运行时间s (S)": "5",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "58",
  "系统运行时间s (S)": "17",
  "累积用电量KWh (S)": "54320.7734375",
  "膜产水压力值 (S)": "-20.6333122253418",
  "膜产水瞬时值 (S)": "35",
  "膜产水累积量m3 (S)": "56894",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3164",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700210850",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6779952",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:23:30.850Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "0",
  "产水泵运行时间s (S)": "10",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "58",
  "系统运行时间s (S)": "22",
  "累积用电量KWh (S)": "54320.7890625",
  "膜产水压力值 (S)": "-20.2353096008301",
  "膜产水瞬时值 (S)": "35.7000007629395",
  "膜产水累积量m3 (S)": "56894",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3171",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700220837",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6781188",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:23:40.837Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "0",
  "产水泵运行时间s (S)": "28",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "58",
  "系统运行时间s (S)": "40",
  "累积用电量KWh (S)": "54320.85546875",
  "膜产水压力值 (S)": "-22.9829444885254",
  "膜产水瞬时值 (S)": "36.7000007629395",
  "膜产水累积量m3 (S)": "56894",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3178",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700230866",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6781188",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:23:50.866Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "0",
  "产水泵运行时间s (S)": "33",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "58",
  "系统运行时间s (S)": "45",
  "累积用电量KWh (S)": "54320.8671875",
  "膜产水压力值 (S)": "-21.4074172973633",
  "膜产水瞬时值 (S)": "36.0999984741211",
  "膜产水累积量m3 (S)": "56894",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3156",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700240836",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6781188",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:24:00.836Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "0",
  "产水泵运行时间s (S)": "43",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "58",
  "系统运行时间s (S)": "55",
  "累积用电量KWh (S)": "54320.90234375",
  "膜产水压力值 (S)": "-21.8688564300537",
  "膜产水瞬时值 (S)": "34.5999984741211",
  "膜产水累积量m3 (S)": "56895",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3223",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700250848",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6782630",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:24:10.848Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "0",
  "产水泵运行时间s (S)": "55",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "59",
  "系统运行时间s (S)": "7",
  "累积用电量KWh (S)": "54320.94921875",
  "膜产水压力值 (S)": "-23.742603302002",
  "膜产水瞬时值 (S)": "34.5",
  "膜产水累积量m3 (S)": "56895",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3192",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700260834",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6782630",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:24:20.834Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "1",
  "产水泵运行时间s (S)": "5",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "59",
  "系统运行时间s (S)": "17",
  "累积用电量KWh (S)": "54320.98046875",
  "膜产水压力值 (S)": "-21.3852939605713",
  "膜产水瞬时值 (S)": "36.2999992370605",
  "膜产水累积量m3 (S)": "56895",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3174",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700270850",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6782630",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:24:30.850Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "1",
  "产水泵运行时间s (S)": "17",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "59",
  "系统运行时间s (S)": "29",
  "累积用电量KWh (S)": "54321.0234375",
  "膜产水压力值 (S)": "-23.8755702972412",
  "膜产水瞬时值 (S)": "37.5",
  "膜产水累积量m3 (S)": "56895",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3254",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700280829",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6784278",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:24:40.829Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "1",
  "产水泵运行时间s (S)": "25",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "59",
  "系统运行时间s (S)": "37",
  "累积用电量KWh (S)": "54321.0546875",
  "膜产水压力值 (S)": "-22.1514987945557",
  "膜产水瞬时值 (S)": "37.9000015258789",
  "膜产水累积量m3 (S)": "56895",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3175",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700290815",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6784278",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:24:50.815Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "1",
  "产水泵运行时间s (S)": "34",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "59",
  "系统运行时间s (S)": "46",
  "累积用电量KWh (S)": "54321.0859375",
  "膜产水压力值 (S)": "-21.6367816925049",
  "膜产水瞬时值 (S)": "39.4000015258789",
  "膜产水累积量m3 (S)": "56895",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3205",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700300907",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6784278",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:25:00.907Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "1",
  "产水泵运行时间s (S)": "39",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "4",
  "系统运行时间m (S)": "59",
  "系统运行时间s (S)": "51",
  "累积用电量KWh (S)": "54321.10546875",
  "膜产水压力值 (S)": "-23.0946922302246",
  "膜产水瞬时值 (S)": "39.7000007629395",
  "膜产水累积量m3 (S)": "56895",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3232",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700310811",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6785514",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:25:10.811Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "1",
  "产水泵运行时间s (S)": "58",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "0",
  "系统运行时间s (S)": "10",
  "累积用电量KWh (S)": "54321.16796875",
  "膜产水压力值 (S)": "-21.6216564178467",
  "膜产水瞬时值 (S)": "40.5",
  "膜产水累积量m3 (S)": "56895",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3178",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700320845",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6785514",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:25:20.845Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "2",
  "产水泵运行时间s (S)": "5",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "0",
  "系统运行时间s (S)": "17",
  "累积用电量KWh (S)": "54321.1953125",
  "膜产水压力值 (S)": "-23.00710105896",
  "膜产水瞬时值 (S)": "40.2999992370605",
  "膜产水累积量m3 (S)": "56895",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3243",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700330823",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6785514",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:25:30.823Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "2",
  "产水泵运行时间s (S)": "14",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "0",
  "系统运行时间s (S)": "26",
  "累积用电量KWh (S)": "54321.23046875",
  "膜产水压力值 (S)": "-24.2947940826416",
  "膜产水瞬时值 (S)": "38.9000015258789",
  "膜产水累积量m3 (S)": "56895",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3229",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700340826",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6786956",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:25:40.826Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "2",
  "产水泵运行时间s (S)": "20",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "0",
  "系统运行时间s (S)": "32",
  "累积用电量KWh (S)": "54321.2578125",
  "膜产水压力值 (S)": "-22.6352863311768",
  "膜产水瞬时值 (S)": "38",
  "膜产水累积量m3 (S)": "56896",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3164",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700350840",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6786956",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:25:50.840Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "2",
  "产水泵运行时间s (S)": "29",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "0",
  "系统运行时间s (S)": "41",
  "累积用电量KWh (S)": "54321.28125",
  "膜产水压力值 (S)": "-21.7661380767822",
  "膜产水瞬时值 (S)": "39.2999992370605",
  "膜产水累积量m3 (S)": "56896",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3192",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700360866",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6786956",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:26:00.866Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "2",
  "产水泵运行时间s (S)": "40",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "0",
  "系统运行时间s (S)": "52",
  "累积用电量KWh (S)": "54321.32421875",
  "膜产水压力值 (S)": "-24.4686222076416",
  "膜产水瞬时值 (S)": "39.5",
  "膜产水累积量m3 (S)": "56896",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3243",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700370824",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6788192",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:26:10.824Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "2",
  "产水泵运行时间s (S)": "53",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "1",
  "系统运行时间s (S)": "5",
  "累积用电量KWh (S)": "54321.3671875",
  "膜产水压力值 (S)": "-22.6723098754883",
  "膜产水瞬时值 (S)": "36.7999992370605",
  "膜产水累积量m3 (S)": "56896",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3188",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700380840",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6788192",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:26:20.840Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "3",
  "产水泵运行时间s (S)": "4",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "1",
  "系统运行时间s (S)": "16",
  "累积用电量KWh (S)": "54321.40625",
  "膜产水压力值 (S)": "-24.0200519561768",
  "膜产水瞬时值 (S)": "37.0999984741211",
  "膜产水累积量m3 (S)": "56896",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3235",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700390818",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6788192",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:26:30.818Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "3",
  "产水泵运行时间s (S)": "15",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "1",
  "系统运行时间s (S)": "27",
  "累积用电量KWh (S)": "54321.44921875",
  "膜产水压力值 (S)": "-24.3392677307129",
  "膜产水瞬时值 (S)": "36.5",
  "膜产水累积量m3 (S)": "56896",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3178",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700400866",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6789840",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:26:40.866Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "3",
  "产水泵运行时间s (S)": "25",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "1",
  "系统运行时间s (S)": "37",
  "累积用电量KWh (S)": "54321.484375",
  "膜产水压力值 (S)": "-22.5309886932373",
  "膜产水瞬时值 (S)": "38.5999984741211",
  "膜产水累积量m3 (S)": "56896",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3187",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700410837",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6789840",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:26:50.837Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "3",
  "产水泵运行时间s (S)": "36",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "1",
  "系统运行时间s (S)": "48",
  "累积用电量KWh (S)": "54321.5234375",
  "膜产水压力值 (S)": "-24.6065578460693",
  "膜产水瞬时值 (S)": "39.4000015258789",
  "膜产水累积量m3 (S)": "56896",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3245",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700420814",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6789840",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:27:00.814Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "3",
  "产水泵运行时间s (S)": "45",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "1",
  "系统运行时间s (S)": "57",
  "累积用电量KWh (S)": "54321.5546875",
  "膜产水压力值 (S)": "-23.5486812591553",
  "膜产水瞬时值 (S)": "37.2999992370605",
  "膜产水累积量m3 (S)": "56896",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3172",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700430801",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6791076",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:27:10.801Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "3",
  "产水泵运行时间s (S)": "49",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "2",
  "系统运行时间s (S)": "1",
  "累积用电量KWh (S)": "54321.56640625",
  "膜产水压力值 (S)": "-22.5357284545898",
  "膜产水瞬时值 (S)": "35.9000015258789",
  "膜产水累积量m3 (S)": "56896",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3177",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700441254",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6791076",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:27:21.254Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "4",
  "产水泵运行时间s (S)": "6",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "2",
  "系统运行时间s (S)": "18",
  "累积用电量KWh (S)": "54321.625",
  "膜产水压力值 (S)": "-24.7232723236084",
  "膜产水瞬时值 (S)": "34.4000015258789",
  "膜产水累积量m3 (S)": "56897",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3248",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700450838",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6791076",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:27:30.838Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "4",
  "产水泵运行时间s (S)": "6",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "2",
  "系统运行时间s (S)": "18",
  "累积用电量KWh (S)": "54321.625",
  "膜产水压力值 (S)": "-24.7232723236084",
  "膜产水瞬时值 (S)": "34.4000015258789",
  "膜产水累积量m3 (S)": "56897",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3248",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700460818",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6792518",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:27:40.818Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "4",
  "产水泵运行时间s (S)": "26",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "2",
  "系统运行时间s (S)": "38",
  "累积用电量KWh (S)": "54321.703125",
  "膜产水压力值 (S)": "-24.0112476348877",
  "膜产水瞬时值 (S)": "38.2000007629395",
  "膜产水累积量m3 (S)": "56897",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3232",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700470864",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6792518",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:27:50.864Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "4",
  "产水泵运行时间s (S)": "26",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "2",
  "系统运行时间s (S)": "38",
  "累积用电量KWh (S)": "54321.703125",
  "膜产水压力值 (S)": "-24.0112476348877",
  "膜产水瞬时值 (S)": "38.2000007629395",
  "膜产水累积量m3 (S)": "56897",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3232",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700480839",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6792518",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:28:00.839Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "4",
  "产水泵运行时间s (S)": "39",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "2",
  "系统运行时间s (S)": "51",
  "累积用电量KWh (S)": "54321.74609375",
  "膜产水压力值 (S)": "-23.9875450134277",
  "膜产水瞬时值 (S)": "37.4000015258789",
  "膜产水累积量m3 (S)": "56897",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3182",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700490880",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6793754",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:28:10.880Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "4",
  "产水泵运行时间s (S)": "52",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "3",
  "系统运行时间s (S)": "4",
  "累积用电量KWh (S)": "54321.796875",
  "膜产水压力值 (S)": "-23.8796348571777",
  "膜产水瞬时值 (S)": "37.7000007629395",
  "膜产水累积量m3 (S)": "56897",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3226",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700500827",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6793754",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:28:20.827Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "5",
  "产水泵运行时间s (S)": "3",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "3",
  "系统运行时间s (S)": "15",
  "累积用电量KWh (S)": "54321.83203125",
  "膜产水压力值 (S)": "-24.746072769165",
  "膜产水瞬时值 (S)": "37.5",
  "膜产水累积量m3 (S)": "56897",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3226",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700510866",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6793754",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:28:30.866Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "5",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "3",
  "系统运行时间s (S)": "25",
  "累积用电量KWh (S)": "54321.8671875",
  "膜产水压力值 (S)": "-22.79128074646",
  "膜产水瞬时值 (S)": "35.5",
  "膜产水累积量m3 (S)": "56897",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3173",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700520839",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6794990",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:28:40.839Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "5",
  "产水泵运行时间s (S)": "23",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "3",
  "系统运行时间s (S)": "35",
  "累积用电量KWh (S)": "54321.8984375",
  "膜产水压力值 (S)": "-24.5036144256592",
  "膜产水瞬时值 (S)": "34.2999992370605",
  "膜产水累积量m3 (S)": "56897",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3220",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700530830",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6794990",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:28:50.830Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "5",
  "产水泵运行时间s (S)": "33",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "3",
  "系统运行时间s (S)": "45",
  "累积用电量KWh (S)": "54321.94140625",
  "膜产水压力值 (S)": "-24.4115085601807",
  "膜产水瞬时值 (S)": "34.7000007629395",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3180",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700540822",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6794990",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:29:00.822Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "5",
  "产水泵运行时间s (S)": "45",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "3",
  "系统运行时间s (S)": "57",
  "累积用电量KWh (S)": "54321.98046875",
  "膜产水压力值 (S)": "-23.5371685028076",
  "膜产水瞬时值 (S)": "37.7000007629395",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3223",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700551014",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6796432",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:29:11.014Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "5",
  "产水泵运行时间s (S)": "54",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "4",
  "系统运行时间s (S)": "6",
  "累积用电量KWh (S)": "54322.015625",
  "膜产水压力值 (S)": "-24.964376449585",
  "膜产水瞬时值 (S)": "38",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3232",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700560849",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6796432",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:29:20.849Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "5",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "4",
  "系统运行时间s (S)": "17",
  "累积用电量KWh (S)": "54322.05078125",
  "膜产水压力值 (S)": "-22.8068580627441",
  "膜产水瞬时值 (S)": "37.4000015258789",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3165",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700570873",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6796432",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:29:30.873Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "4",
  "系统运行时间s (S)": "27",
  "累积用电量KWh (S)": "54322.08984375",
  "膜产水压力值 (S)": "-12.5030584335327",
  "膜产水瞬时值 (S)": "36.5999984741211",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2656",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700580842",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6797874",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:29:40.842Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "4",
  "系统运行时间s (S)": "37",
  "累积用电量KWh (S)": "54322.1171875",
  "膜产水压力值 (S)": "2.37019968032837",
  "膜产水瞬时值 (S)": "28.6000003814697",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2656",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700590877",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6797874",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:29:50.877Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "4",
  "系统运行时间s (S)": "48",
  "累积用电量KWh (S)": "54322.1484375",
  "膜产水压力值 (S)": "4.16199779510498",
  "膜产水瞬时值 (S)": "13.1999998092651",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2657",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700600844",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6797874",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:30:00.844Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "4",
  "系统运行时间s (S)": "58",
  "累积用电量KWh (S)": "54322.17578125",
  "膜产水压力值 (S)": "4.84083604812622",
  "膜产水瞬时值 (S)": "2.20000004768372",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2660",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700610861",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6799110",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:30:10.861Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "5",
  "系统运行时间s (S)": "8",
  "累积用电量KWh (S)": "54322.203125",
  "膜产水压力值 (S)": "6.5901927947998",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2658",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700620826",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6799110",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:30:20.826Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "5",
  "系统运行时间s (S)": "18",
  "累积用电量KWh (S)": "54322.23828125",
  "膜产水压力值 (S)": "7.02454090118408",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2660",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700630906",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6799110",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:30:30.906Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "5",
  "系统运行时间s (S)": "28",
  "累积用电量KWh (S)": "54322.2734375",
  "膜产水压力值 (S)": "7.3649754524231",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2659",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700640881",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6800346",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:30:40.881Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "5",
  "系统运行时间s (S)": "38",
  "累积用电量KWh (S)": "54322.30078125",
  "膜产水压力值 (S)": "7.47852897644043",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2658",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700650815",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6800346",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:30:50.815Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "5",
  "系统运行时间s (S)": "48",
  "累积用电量KWh (S)": "54322.328125",
  "膜产水压力值 (S)": "7.45076179504394",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2660",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700660867",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6800346",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:31:00.867Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "5",
  "系统运行时间s (S)": "58",
  "累积用电量KWh (S)": "54322.359375",
  "膜产水压力值 (S)": "7.48959112167358",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2665",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700670833",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6801582",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:31:10.833Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "6",
  "系统运行时间s (S)": "9",
  "累积用电量KWh (S)": "54322.3828125",
  "膜产水压力值 (S)": "7.60991716384888",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2656",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700680862",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6801582",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:31:20.862Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "6",
  "系统运行时间s (S)": "18",
  "累积用电量KWh (S)": "54322.421875",
  "膜产水压力值 (S)": "7.62007617950439",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2655",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700690841",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6801582",
  "$SIGNAL (S)": "28",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:31:30.841Z",
  "产水泵 (S)": "0",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "13",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "6",
  "系统运行时间s (S)": "23",
  "累积用电量KWh (S)": "54322.4296875",
  "膜产水压力值 (S)": "7.69683218002319",
  "膜产水瞬时值 (S)": "0",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "2657",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700700869",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6802612",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:31:40.869Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "23",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "6",
  "系统运行时间s (S)": "35",
  "累积用电量KWh (S)": "54322.47265625",
  "膜产水压力值 (S)": "-19.2352256774902",
  "膜产水瞬时值 (S)": "4.90000009536743",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3238",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700710897",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6802612",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:31:50.897Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "33",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "6",
  "系统运行时间s (S)": "45",
  "累积用电量KWh (S)": "54322.5078125",
  "膜产水压力值 (S)": "-23.2321758270264",
  "膜产水瞬时值 (S)": "23.2000007629395",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3236",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700720852",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6804260",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:32:00.852Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "48",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "7",
  "系统运行时间s (S)": "0",
  "累积用电量KWh (S)": "54322.5625",
  "膜产水压力值 (S)": "-24.509033203125",
  "膜产水瞬时值 (S)": "46.4000015258789",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3218",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700730827",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6804260",
  "$SIGNAL (S)": "26",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:32:10.827Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "6",
  "产水泵运行时间s (S)": "54",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "7",
  "系统运行时间s (S)": "6",
  "累积用电量KWh (S)": "54322.58984375",
  "膜产水压力值 (S)": "-22.7330379486084",
  "膜产水瞬时值 (S)": "48.9000015258789",
  "膜产水累积量m3 (S)": "56898",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3160",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700740871",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6804260",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:32:20.871Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "7",
  "产水泵运行时间s (S)": "6",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "7",
  "系统运行时间s (S)": "18",
  "累积用电量KWh (S)": "54322.62890625",
  "膜产水压力值 (S)": "-23.245044708252",
  "膜产水瞬时值 (S)": "46.2999992370605",
  "膜产水累积量m3 (S)": "56899",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3235",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700750847",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6804260",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:32:30.847Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "7",
  "产水泵运行时间s (S)": "12",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "7",
  "系统运行时间s (S)": "24",
  "累积用电量KWh (S)": "54322.65234375",
  "膜产水压力值 (S)": "-24.1708545684814",
  "膜产水瞬时值 (S)": "44",
  "膜产水累积量m3 (S)": "56899",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3231",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700760835",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6805496",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:32:40.835Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "7",
  "产水泵运行时间s (S)": "24",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "7",
  "系统运行时间s (S)": "36",
  "累积用电量KWh (S)": "54322.69140625",
  "膜产水压力值 (S)": "-22.0374927520752",
  "膜产水瞬时值 (S)": "37.7000007629395",
  "膜产水累积量m3 (S)": "56899",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3166",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700770856",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6805496",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:32:50.856Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "7",
  "产水泵运行时间s (S)": "34",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "7",
  "系统运行时间s (S)": "46",
  "累积用电量KWh (S)": "54322.7265625",
  "膜产水压力值 (S)": "-24.0609130859375",
  "膜产水瞬时值 (S)": "36.2000007629395",
  "膜产水累积量m3 (S)": "56899",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3239",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700780843",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6805496",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:33:00.843Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "7",
  "产水泵运行时间s (S)": "44",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "7",
  "系统运行时间s (S)": "56",
  "累积用电量KWh (S)": "54322.765625",
  "膜产水压力值 (S)": "-23.5132389068604",
  "膜产水瞬时值 (S)": "35.7999992370605",
  "膜产水累积量m3 (S)": "56899",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3175",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700791241",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6806938",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:33:11.241Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "7",
  "产水泵运行时间s (S)": "58",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "8",
  "系统运行时间s (S)": "10",
  "累积用电量KWh (S)": "54322.80859375",
  "膜产水压力值 (S)": "-23.536491394043",
  "膜产水瞬时值 (S)": "34",
  "膜产水累积量m3 (S)": "56899",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3209",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700800851",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6806938",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:33:20.851Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "8",
  "产水泵运行时间s (S)": "3",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "8",
  "系统运行时间s (S)": "15",
  "累积用电量KWh (S)": "54322.828125",
  "膜产水压力值 (S)": "-24.5139999389648",
  "膜产水瞬时值 (S)": "34",
  "膜产水累积量m3 (S)": "56899",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3223",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700810883",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6806938",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:33:30.883Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "8",
  "产水泵运行时间s (S)": "16",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "8",
  "系统运行时间s (S)": "28",
  "累积用电量KWh (S)": "54322.875",
  "膜产水压力值 (S)": "-22.5526599884033",
  "膜产水瞬时值 (S)": "35.2999992370605",
  "膜产水累积量m3 (S)": "56899",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3180",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700820827",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6808380",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:33:40.827Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "8",
  "产水泵运行时间s (S)": "22",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "8",
  "系统运行时间s (S)": "34",
  "累积用电量KWh (S)": "54322.89453125",
  "膜产水压力值 (S)": "-23.1502285003662",
  "膜产水瞬时值 (S)": "36.7000007629395",
  "膜产水累积量m3 (S)": "56899",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3189",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700830865",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6808380",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:33:50.865Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "8",
  "产水泵运行时间s (S)": "35",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "8",
  "系统运行时间s (S)": "47",
  "累积用电量KWh (S)": "54322.94140625",
  "膜产水压力值 (S)": "-24.7205638885498",
  "膜产水瞬时值 (S)": "37.0999984741211",
  "膜产水累积量m3 (S)": "56899",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3170",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700840885",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6808380",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:34:00.885Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "8",
  "产水泵运行时间s (S)": "41",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "8",
  "系统运行时间s (S)": "53",
  "累积用电量KWh (S)": "54322.96484375",
  "膜产水压力值 (S)": "-23.006196975708",
  "膜产水瞬时值 (S)": "36.0999984741211",
  "膜产水累积量m3 (S)": "56900",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3155",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700850835",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6809410",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:34:10.835Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "8",
  "产水泵运行时间s (S)": "53",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "9",
  "系统运行时间s (S)": "5",
  "累积用电量KWh (S)": "54323.00390625",
  "膜产水压力值 (S)": "-24.4821681976318",
  "膜产水瞬时值 (S)": "34.9000015258789",
  "膜产水累积量m3 (S)": "56900",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3217",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700860860",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6809410",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:34:20.860Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "8",
  "产水泵运行时间s (S)": "58",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "9",
  "系统运行时间s (S)": "10",
  "累积用电量KWh (S)": "54323.02734375",
  "膜产水压力值 (S)": "-24.9706974029541",
  "膜产水瞬时值 (S)": "35.2999992370605",
  "膜产水累积量m3 (S)": "56900",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3214",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700871361",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6810852",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:34:31.361Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "9",
  "产水泵运行时间s (S)": "18",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "9",
  "系统运行时间s (S)": "30",
  "累积用电量KWh (S)": "54323.09765625",
  "膜产水压力值 (S)": "-24.1187057495117",
  "膜产水瞬时值 (S)": "37.2999992370605",
  "膜产水累积量m3 (S)": "56900",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3197",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700880859",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6810852",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:34:40.859Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "9",
  "产水泵运行时间s (S)": "28",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "9",
  "系统运行时间s (S)": "40",
  "累积用电量KWh (S)": "54323.1328125",
  "膜产水压力值 (S)": "-25.0768013000488",
  "膜产水瞬时值 (S)": "35.7999992370605",
  "膜产水累积量m3 (S)": "56900",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3207",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700890840",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6810852",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:34:50.840Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "9",
  "产水泵运行时间s (S)": "34",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "9",
  "系统运行时间s (S)": "46",
  "累积用电量KWh (S)": "54323.15234375",
  "膜产水压力值 (S)": "-23.6491413116455",
  "膜产水瞬时值 (S)": "36",
  "膜产水累积量m3 (S)": "56900",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3159",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700900888",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6810852",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:35:00.888Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "9",
  "产水泵运行时间s (S)": "44",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "9",
  "系统运行时间s (S)": "56",
  "累积用电量KWh (S)": "54323.1875",
  "膜产水压力值 (S)": "-23.9460067749023",
  "膜产水瞬时值 (S)": "37.2000007629395",
  "膜产水累积量m3 (S)": "56900",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3181",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700910877",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6812500",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:35:10.877Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "9",
  "产水泵运行时间s (S)": "54",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "10",
  "系统运行时间s (S)": "6",
  "累积用电量KWh (S)": "54323.22265625",
  "膜产水压力值 (S)": "-25.3885650634766",
  "膜产水瞬时值 (S)": "37.0999984741211",
  "膜产水累积量m3 (S)": "56900",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3223",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700920871",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6812500",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:35:20.871Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "10",
  "产水泵运行时间s (S)": "4",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "10",
  "系统运行时间s (S)": "16",
  "累积用电量KWh (S)": "54323.26171875",
  "膜产水压力值 (S)": "-23.4416751861572",
  "膜产水瞬时值 (S)": "36.5",
  "膜产水累积量m3 (S)": "56900",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3161",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700930872",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6812500",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:35:30.872Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "10",
  "产水泵运行时间s (S)": "10",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "10",
  "系统运行时间s (S)": "22",
  "累积用电量KWh (S)": "54323.28125",
  "膜产水压力值 (S)": "-23.5834465026855",
  "膜产水瞬时值 (S)": "36.2999992370605",
  "膜产水累积量m3 (S)": "56900",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3177",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700940949",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6813736",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:35:40.949Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "10",
  "产水泵运行时间s (S)": "23",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "10",
  "系统运行时间s (S)": "35",
  "累积用电量KWh (S)": "54323.32421875",
  "膜产水压力值 (S)": "-25.4161071777344",
  "膜产水瞬时值 (S)": "38.2000007629395",
  "膜产水累积量m3 (S)": "56901",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3219",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700950883",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6813736",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:35:50.883Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "10",
  "产水泵运行时间s (S)": "37",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "10",
  "系统运行时间s (S)": "49",
  "累积用电量KWh (S)": "54323.37109375",
  "膜产水压力值 (S)": "-23.7502784729004",
  "膜产水瞬时值 (S)": "37.7000007629395",
  "膜产水累积量m3 (S)": "56901",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3204",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700960859",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6813736",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:36:00.859Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "10",
  "产水泵运行时间s (S)": "39",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "10",
  "系统运行时间s (S)": "51",
  "累积用电量KWh (S)": "54323.37890625",
  "膜产水压力值 (S)": "-24.325044631958",
  "膜产水瞬时值 (S)": "37.7000007629395",
  "膜产水累积量m3 (S)": "56901",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3225",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700970876",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6815384",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:36:10.876Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "10",
  "产水泵运行时间s (S)": "53",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "11",
  "系统运行时间s (S)": "5",
  "累积用电量KWh (S)": "54323.43359375",
  "膜产水压力值 (S)": "-25.4416160583496",
  "膜产水瞬时值 (S)": "35.7999992370605",
  "膜产水累积量m3 (S)": "56901",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3212",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700980857",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6815384",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:36:20.857Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "11",
  "产水泵运行时间s (S)": "8",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "11",
  "系统运行时间s (S)": "20",
  "累积用电量KWh (S)": "54323.48828125",
  "膜产水压力值 (S)": "-24.508581161499",
  "膜产水瞬时值 (S)": "37.7000007629395",
  "膜产水累积量m3 (S)": "56901",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3228",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553700990880",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6815384",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:36:30.880Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "11",
  "产水泵运行时间s (S)": "16",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "11",
  "系统运行时间s (S)": "28",
  "累积用电量KWh (S)": "54323.515625",
  "膜产水压力值 (S)": "-25.5497531890869",
  "膜产水瞬时值 (S)": "36.9000015258789",
  "膜产水累积量m3 (S)": "56901",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3220",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}, {
  "deviceId (S)": "20346186957",
  "timestamp (N)": "1553701000875",
  "$Com1Error (S)": "0",
  "$Com2Error (S)": "0",
  "$Day (S)": "27",
  "$Month (S)": "3",
  "$NetTraffic (S)": "6816620",
  "$SIGNAL (S)": "27",
  "$WeekDay (S)": "3",
  "dateTime (S)": "2019-03-27T15:36:40.875Z",
  "产水泵 (S)": "1",
  "产水泵故障 (S)": "0",
  "产水泵运行时间d (S)": "60",
  "产水泵运行时间h (S)": "6",
  "产水泵运行时间m (S)": "11",
  "产水泵运行时间s (S)": "18",
  "曝气压力下限 (S)": "0",
  "污水提升泵 (S)": "0",
  "污水提升泵故障 (S)": "0",
  "系统运行时间d (S)": "149",
  "系统运行时间h (S)": "5",
  "系统运行时间m (S)": "11",
  "系统运行时间s (S)": "30",
  "累积用电量KWh (S)": "54323.5234375",
  "膜产水压力值 (S)": "-25.5542678833008",
  "膜产水瞬时值 (S)": "36.9000015258789",
  "膜产水累积量m3 (S)": "56901",
  "膜压力到下限 (S)": "0",
  "膜池低液位 (S)": "0",
  "膜池高液位 (S)": "1",
  "设备有功功率W (S)": "3218",
  "集水池低液位 (S)": "0",
  "集水池高液位 (S)": "0",
  "鼓风机1 (S)": "1",
  "鼓风机1故障 (S)": "0",
  "鼓风机2 (S)": "1",
  "鼓风机2故障 (S)": "0"
}];

export const deviceData = () =>
  data.map(p => {
    p.Date = new Date(p["dateTime (S)"]);
    return p;
  }
);
