import { Component, OnInit } from '@angular/core';
import { mockData } from './weather-data';
import { deviceData } from './device-data';

@Component({
  selector: 'my-app',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    this.deviceStatus = this.data[0];
  }
  public data: any[] = deviceData();
  public deviceStatus = null;

  /**
   * getDateLabel
e   */
  public getDateLabel(e: any) {
    console.log(e);
    return e;  
  }
}
